import client1 from "../assets/images/client/01.png"
import client2 from "../assets/images/client/02.png"
import client3 from "../assets/images/client/03.png"
import client4 from "../assets/images/client/04.png"
import client5 from "../assets/images/client/05.jpg"
import client6 from "../assets/images/client/06.jpg"
import client7 from "../assets/images/client/07.jpg"
import client8 from "../assets/images/client/08.jpg"

import brand1 from "../assets/images/brands/2.png"
import brand2 from "../assets/images/brands/22.png"


import team1 from "../assets/images/client/09.jpg"
import team2 from "../assets/images/client/10.jpg"
import team3 from "../assets/images/client/11.jpg"
import team4 from "../assets/images/client/12.jpg"

import blog1 from "../assets/images/blog/1.jpg"
import blog2 from "../assets/images/blog/2.jpg"
import blog3 from "../assets/images/blog/3.jpg"
import blog4 from "../assets/images/blog/4.jpg"
import blog5 from "../assets/images/blog/5.jpg"
import blog6 from "../assets/images/blog/6.jpg"
import blog7 from "../assets/images/blog/7.jpg"
import blog8 from "../assets/images/blog/8.jpg"
import blog9 from "../assets/images/blog/9.jpg"

import portfolio1 from "../assets/images/portfolio/1.jpg"
import portfolio2 from "../assets/images/portfolio/2.jpg"
import portfolio3 from "../assets/images/portfolio/3.jpg"
import portfolio4 from "../assets/images/portfolio/4.jpg"
import portfolio5 from "../assets/images/portfolio/5.jpg"
import portfolio6 from "../assets/images/portfolio/6.jpg"
import portfolio7 from "../assets/images/portfolio/7.jpg"
import portfolio8 from "../assets/images/portfolio/8.jpg"
import portfolio9 from "../assets/images/portfolio/9.jpg"
import portfolio10 from "../assets/images/portfolio/10.jpg"
import portfolio11 from "../assets/images/portfolio/11.jpg"
import portfolio12 from "../assets/images/portfolio/12.jpg"
import portfolio13 from "../assets/images/portfolio/13.jpg"
import portfolio14 from "../assets/images/portfolio/14.jpg"
import portfolio15 from "../assets/images/portfolio/15.jpg"
import portfolio16 from "../assets/images/portfolio/16.jpg"

import food1 from "../assets/images/food/1.jpg"
import food2 from "../assets/images/food/2.jpg"
import food3 from "../assets/images/food/3.jpg"
import food4 from "../assets/images/food/4.jpg"
import food5 from "../assets/images/food/5.jpg"
import food6 from "../assets/images/food/6.jpg"
import food7 from "../assets/images/food/7.jpg"
import food8 from "../assets/images/food/8.jpg"
import food9 from "../assets/images/food/9.jpg"
import food10 from "../assets/images/food/10.jpg"
import food11 from "../assets/images/food/11.jpg"
import food12 from "../assets/images/food/12.jpg"
import food13 from "../assets/images/food/13.jpg"
import food14 from "../assets/images/food/14.jpg"
import food15 from "../assets/images/food/15.jpg"

import faceBook from "../assets/images/brands/facebook-logo-2019.png"
import google from "../assets/images/brands/google-logo.png"
import linkedin from "../assets/images/brands/linkedin.png"
import skype from "../assets/images/brands/skype.png"
import spotify from "../assets/images/brands/spotify.png"
import telegram from "../assets/images/brands/telegram.png"
import circle from "../assets/images/brands/circle-logo.png"
import lenovo from "../assets/images/brands/lenovo-logo.png"
import shreeLogo from "../assets/images/brands/shree-logo.png"
import snapchat from "../assets/images/brands/snapchat.png"
import android from "../assets/images/brands/android.png"


import {HiOutlinePresentationChartLine,LiaFileInvoiceDollarSolid,AiOutlineMoneyCollect,RiPresentationLine,AiOutlineDollarCircle,RiFileList3Line} from "../assets/icons/vander"

export const featuresData = [
    {
        id:1,
        icon:HiOutlinePresentationChartLine,
        title:"Digital Marketing",
        desc:"The phrasal sequence of the Lorem Ipsum text is now so that many DTP programmes can generate",
    },
    {
        id:2,
        icon:LiaFileInvoiceDollarSolid,
        title:"Investing",
        desc:"The phrasal sequence of the Lorem Ipsum text is now so that many DTP programmes can generate",
    },
    {
        id:3,
        icon:AiOutlineMoneyCollect,
        title:"Mortgage Advisor",
        desc:"The phrasal sequence of the Lorem Ipsum text is now so that many DTP programmes can generate",
    },
    {
        id:4,
        icon:RiPresentationLine,
        title:"Real Estate",
        desc:"The phrasal sequence of the Lorem Ipsum text is now so that many DTP programmes can generate",
    },
    {
        id:5,
        icon:AiOutlineDollarCircle,
        title:"Payroll & Accounting",
        desc:"The phrasal sequence of the Lorem Ipsum text is now so that many DTP programmes can generate",
    },
    {
        id:6,
        icon:RiFileList3Line,
        title:"Branch Registration",
        desc:"The phrasal sequence of the Lorem Ipsum text is now so that many DTP programmes can generate",
    },
]
export const accordionData =[
    {
        id:0,
        title:"How does it work ?",
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
    {
        id:1,
        title:"Do I need a designer to use Fronter ?",
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
    {
        id:2,
        title:"What do I need to do to start selling ?",
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
    {
        id:3,
        title:"What happens when I receive an order ?",
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
]
export const accordionData2 =[
    {
        id:0,
        title:"How does it work ?",
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
    {
        id:1,
        title:"Do I need a designer to use Fronter ?",
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
    {
        id:2,
        title:"What do I need to do to start selling ?",
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
    {
        id:3,
        title:"Does CMR offer relocation assistance?",
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
    {
        id:4,
        title:"How is management perceived at CMR?",
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
    {
        id:5,
        title:"How is the work/life balance at CMR?",
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
]

export const clientsData = [
    {
        image:client2,
        name:"Graphic Gallery",
        title:"Graphic Partner",
        desc:"Brought our vision to life with a website that beautifully showcases our design portfolio. Their attention to detail, seamless communication, and technical expertise made the collaboration effortless and the result outstanding."
    },
    {
        image:client1,
        name:"Dasamuga Entertainment",
        title:"Production Company",
        desc:"Exceeded our expectations by creating a visually stunning and user-friendly website that perfectly captures our brand's essence. Their professionalism and creativity made the entire process seamless and enjoyable."
    },
    {
        image:client3,
        name:"Detect AI",
        title:"AI Start Up",
        desc:"Delivered a cutting-edge website that perfectly aligns with our AI-driven mission. Their innovative approach, technical prowess, and ability to capture our brand’s essence made this partnership a true success."
    },
    {
        image:client4,
        name:"Piraveen Subramaniam",
        title:"Exculutive Client",
        desc:"Its very nice way to handile each and every project, i did 3 more web development prrojects its vaery charmfull developers and finished the work given time thanks agian for the big help"
    }
]

export const partnersImg = [brand1,brand2]

export const teamData = [
    {
        image:team1,
        name:"Calvin Carlo",
        title:"Designer"
    },
    {
        image:team2,
        name:"Aliana Rosy",
        title:"Designer"
    },
    {
        image:team3,
        name:"Aliana Rosy",
        title:"Designer"
    },
    {
        image:team4,
        name:"Aliana Rosy",
        title:"Designer"
    },
]
export const teamTwoData = [
    {
        id:1,
        image:client1,
        name:"Calvin Carlo",
        title:"Designer"
    },
    {
        id:2,
        image:client2,
        name:"Aliana Rosy",
        title:"Designer"
    },
    {
        id:3,
        image:client3,
        name:"Micheal Carlo",
        title:"Designer"
    },
    {
        id:4,
        image:client4,
        name:"Sofia Razaq",
        title:"Designer"
    },
    {
        id:5,
        image:client5,
        name:"Jack John",
        title:"Designer"
    },
    {
        id:6,
        image:client6,
        name:"Krista John",
        title:"Designer"
    },
    {
        id:7,
        image:client7,
        name:"Roger Jackson",
        title:"Designer"
    },
    {
        id:8,
        image:client8,
        name:"Johnny English",
        title:"Designer"
    },
]

export const blogData = [
    {
        id:1,
        image:blog1,
        title:"Building Your Corporate Identity from Fronter",
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        tag:"Corporate",
        date:"13th Sep 2023"
    },
    {
        id:2,
        image:blog2,
        title:"The Dark Side of Overnight Success",
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        tag:"Branding",
        date:"29th Nov 2023"
    },
    {
        id:3,
        image:blog3,
        title:"The Right Hand of Business IT World",
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        tag:"Technology",
        date:"29th Dec 2023"
    },
    {
        id:4,
        image:blog4,
        title:"How to Create Your Own Viral Moments",
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        tag:"Corporate",
        date:"13th March 2023"
    },
    {
        id:5,
        image:blog5,
        title:"How to Write a Business Plan For Any Business",
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        tag:"Branding",
        date:"6th May 2023"
    },
    {
        id:6,
        image:blog6,
        title:"Seeing the Customer Journey More Clearly",
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        tag:"Technology",
        date:"19th June 2023"
    },
    {
        id:7,
        image:blog7,
        title:"The Signs of a Highly Giftable Product",
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        tag:"Corporate",
        date:"20th June 2023"
    },
    {
        id:8,
        image:blog8,
        title:"Defining Your Business Target Audience",
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        tag:"Branding",
        date:"31st Aug 2023"
    },
    {
        id:9,
        image:blog9,
        title:"Running Out of Time & Ideas? Visit Our Blog",
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        tag:"Technology",
        date:"1st Sep 2023"
    },
]

export const portfolioData = [
    {
        id:0,
        image: portfolio1,
        name:'Iphone mockup',
        title:'Branding',
        category: 'branding',
    },
    {
        id:1,
        image: portfolio2,
        name:'Mockup Collection',
        title:'Mockup',
        category: 'designing',
    },
    {
        id:2,
        image: portfolio3,
        name:'Abstract images',
        title:'Abstract',
        category: 'photography',
    },
    {
        id:3,
        image: portfolio4,
        name:'Company V-card',
        title:'V-card',
        category: 'development',
    },
    {
        id:4,
        image: portfolio5,
        name:'Mockup box with paints',
        title:'Photography',
        category: 'branding',
    },
    {
        id:5,
        image: portfolio6,
        name:'Coffee cup',
        title:'Cups',
        category: 'photography',
    },
    {
        id:6,
        image: portfolio7,
        name:'Yellow bg with Books',
        title:'Books',
        category: 'designing',
    },
    {
        id:7,
        image: portfolio8,
        name:'Yellow bg with Books',
        title:'Books',
        category: 'designing',
    },
    {
        id:8,
        image: portfolio1,
        name:'Iphone mockup',
        title:'Branding',
        category: 'branding',
    },
    {
        id:9,
        image: portfolio2,
        name:'Mockup Collection',
        title:'Mockup',
        category: 'designing',
    },
    {
        id:10,
        image: portfolio3,
        name:'Abstract images',
        title:'Abstract',
        category: 'photography',
    },
    {
        id:11,
        image: portfolio4,
        name:'Company V-card',
        title:'V-card',
        category: 'development',
    },
]
export const masonaryPortfolio = [
    {
        id:0,
        image:portfolio10,
        title:"Iphone mockup",
        name:"Branding",
        category:"branding"
    },
    {
        id:1,
        image:portfolio2,
        title:"Mockup Collection",
        name:"Mockup",
        category:"designing"
    },
    {
        id:2,
        image:portfolio11,
        title:"Abstract images",
        name:"Abstract",
        category:"photography"
    },
    {
        id:3,
        image:portfolio13,
        title:"Company V-card",
        name:"V-card",
        category:"branding"
    },
    {
        id:4,
        image:portfolio6,
        title:"Mockup box with paints",
        name:"Branding",
        category:"Photography"
    },
    {
        id:5,
        image:portfolio7,
        title:"Coffee cup",
        name:"Cups",
        category:"designing"
    },
    {
        id:6,
        image:portfolio14,
        title:"Yellow bg with Books",
        name:"Books",
        category:"development"
    },
    {
        id:7,
        image:portfolio12,
        title:"Yellow bg with Books",
        name:"Books",
        category:"development"
    },
    {
        id:8,
        image:portfolio8,
        title:"Pen and article",
        name:"Article",
        category:"development"
    },
    {
        id:9,
        image:portfolio9,
        title:"Yellow bg with Books",
        name:"Books",
        category:"development"
    },
    {
        id:10,
        image:portfolio1,
        title:"Iphone mockup",
        name:"Branding",
        category:"branding"
    },
    {
        id:11,
        image:portfolio15,
        title:"Mockup Collection",
        name:"Mockup",
        category:"designing"
    },
    {
        id:12,
        image:portfolio16,
        title:"Yellow bg with Books",
        name:"Branding",
        category:"Books"
    },

]
export const masonaryImage = [portfolio10,portfolio2,portfolio11,portfolio13,portfolio6,portfolio7,portfolio14,portfolio12,portfolio8,portfolio9,portfolio1,portfolio15]

export const portfolioImage = [portfolio1,portfolio2, portfolio3, portfolio4,portfolio5,portfolio6,portfolio7,portfolio8,portfolio1,portfolio2,portfolio3,portfolio4]

export const foodData = [
    {
        id:0,
        image:food1,
        title:"Black bean dip",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"break",
    },
    {
        id:1,
        image:food2,
        title:"Onion Pizza",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"lunch",
    },
    {
        id:2,
        image:food3,
        title:"Chicken Breast",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"break",
    },
    {
        id:3,
        image:food4,
        title:"Veg Pizza",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"dinner",
    },
    {
        id:4,
        image:food5,
        title:"Cordon Bleu",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"break",
    },
    {
        id:5,
        image:food6,
        title:"Boerewors",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"lunch",
    },
    {
        id:6,
        image:food7,
        title:"Tarte Tatin",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"break",
    },
    {
        id:7,
        image:food8,
        title:"Green Tea",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"tea",
    },
    {
        id:8,
        image:food9,
        title:"Special Coffee",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"lunch",
    },
    {
        id:9,
        image:food10,
        title:"Lemon Tea",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"dinner",
    },
    {
        id:10,
        image:food11,
        title:"Pancakes",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"tea",
    },
    {
        id:11,
        image:food12,
        title:"American Item",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"lunch",
    },
    {
        id:12,
        image:food13,
        title:"Country side pizza",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"tea",
    },
    {
        id:13,
        image:food14,
        title:"Chilly garlic potato",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"dinner",
    },
    {
        id:14,
        image:food15,
        title:"Brownie with vanilla",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"tea",
    },
]
 
export const foodImg = [food1,food2,food3,food4,food5,food5,food6,food7,food8,food9,food10,food11,food12,food13,food14,food15]

export const jobData = [
    {
        id:1,
        title:"Software Engineering",
        posted:"Posted 3 Days ago",
        jobTime:'Full Time',
        salary:'$950 - $1100/mo',
        image:faceBook,
        name:'Facebook Ltd.',
        location:'Australia',
        tag:'New'
    },
    {
        id:2,
        title:"Web Developer",
        posted:"Posted 3 Days ago",
        jobTime:'Remote',
        salary:'$2500 - $2600/mo',
        image:google,
        name:'Google Ltd.',
        location:'America'
    },
    {
        id:3,
        title:"UX/UI Designer",
        posted:"Posted 3 Days ago",
        jobTime:'Freelance',
        salary:'$3500 - $3600/mo',
        image:linkedin,
        name:'Linkedin Ltd.',
        location:'Canada'
    },
    {
        id:4,
        title:"Human Resource(HR)",
        posted:"Posted 3 Days ago",
        jobTime:'Part Time',
        salary:'$2000 - $2500/mo',
        image:skype,
        name:'Skype Ltd.',
        location:'UK'
    },
    {
        id:5,
        title:"Web Designer",
        posted:"Posted 3 Days ago",
        jobTime:'Full Time',
        salary:'$1500 - $1600/mo',
        image:spotify,
        name:'Spotify Ltd.',
        location:'China'
    },
    {
        id:6,
        title:"Graphic Designer",
        posted:"Posted 3 Days ago",
        jobTime:'Part time',
        salary:'$500 - $600/mo',
        image:telegram,
        name:'Telegram Ltd.',
        location:'India'
    },
    {
        id:7,
        title:"Senior Web Developer",
        posted:"Posted 3 Days ago",
        jobTime:'Full Time',
        salary:'$950 - $1100/mo',
        image:circle,
        name:'Circle CI Ltd.',
        location:'Australia'
    },
    {
        id:8,
        title:"Front-End Developer",
        posted:"Posted 3 Days ago",
        jobTime:'Remote',
        salary:'$2500 - $2600/mo',
        image:lenovo,
        name:'Lenovo Ltd.',
        location:'America'
    },
    {
        id:9,
        title:"Back-End Developer",
        posted:"Posted 3 Days ago",
        jobTime:'Freelance',
        salary:'$3500 - $3600/mo',
        image:shreeLogo,
        name:'Shreethemes Ltd.',
        location:'Canada'
    },
    {
        id:10,
        title:"Data Entry",
        posted:"Posted 3 Days ago",
        jobTime:'Part Time',
        salary:'$2000 - $2500/mo',
        image:snapchat,
        name:'Snapchat Ltd.',
        location:'UK'
    },
    {
        id:11,
        title:"Android Developer",
        posted:"Posted 3 Days ago",
        jobTime:'Full Time',
        salary:'$1500 - $1600/mo',
        image:android,
        name:'Android Ltd.',
        location:'China'
    },
    {
        id:12,
        title:"Sketch Designer",
        posted:"Posted 3 Days ago",
        jobTime:'Part time',
        salary:'$500 - $600/mo',
        image:shreeLogo,
        name:'Shreethemes Ltd.',
        location:'India'
    },
]