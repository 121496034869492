import React,{useState} from "react";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import {BiColor, FaRegComment} from "../assets/icons/vander"
export default function DigitalProject(){
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const  handleShow = () => setShow(true);
    return(
        <>
            <div className="container mt-100 mt-60" id="contact">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="section-title text-center">
                            <h6 className="text-muted fw-normal mb-3">Available for freelance projects</h6>
                            <h1 className="title mb-4">Do you have Design & Development project? <br/> Let's talk.</h1>
                            <div className="mt-4 pt-2">
                            <a href ="https://wa.me/94775821911?text=Hello How Can I help You ?" target="_blank" className="btn" style={{backgroundColor:"green",color:"white"}}>WhatsApp</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
        </>
    )
}